import React, { Fragment, useState } from "react";
import AllMembers from "./AllMembers";
import "./MealItem/Expenses.css";
import FilterBar from "./Layout/FilterBar";
import MemberForm from "./Data/MemberForm";
import "./MembersButton.css";
import { useGlobalContext } from "../../store/context";
import "./Members.css";
import classes from "./page.module.css";
import { useUserContext } from "../../store/user_context";
import LoginPage from "../../LoginPage/LoginPage";
import ConfirmWindowYes from "../DropBox/ConfirmWindowYes";

import { ref, set, get } from "firebase/database";
import { database } from "../../LoginPage/firebase-config";
import Administrator from "./Administrator";

const Members = () => {
  const { myUser } = useUserContext();

  const [submitted] = useState(true);
  const [valueFromChild, setValueFromChild] = useState("all");
  const [showAddMemberButton, setShowAddMemberButton] = useState(true);
  const [showMemberForm, setShowMemberForm] = useState(false);
  const [parent, setParent] = useState("");
  const [parentHolder, setParentHolder] = useState(null);
  const [confirmWindowIsShown, setConfirmWindowIsShown] = useState(false);
  const [memberID, setMemberID] = useState("");
  const { addNewMember } = useGlobalContext();

  const changeStatus = async (referenceNumber) => {
    console.log("in changeStatus");

    try {
      const referencePath = `MemberID/${referenceNumber}`;
      const reference = ref(database, referencePath);

      const snapshot = await get(reference); // Use get function to fetch data

      if (snapshot.exists()) {
        const allocatedReferenceData = snapshot.val();
        allocatedReferenceData.status = "used";

        // Use set method to update the status to "used" at the specific path
        await set(reference, allocatedReferenceData);
        console.log("done............");
      } else {
        console.log("Reference not found in the database.");
      }
    } catch (error) {
      console.error(`Error changing status:`, error);
    }
  };

  const hideConfirmWindowHandler = () => {
    setConfirmWindowIsShown(false);
  };
  const handleValueFromChild = (value) => {
    setValueFromChild(value);
  };

  const AddMemberHandler = () => {
    setShowAddMemberButton(false);
    setShowMemberForm(true);
  };
  const CloseAddMemberFormHandler = () => {
    setShowAddMemberButton(true);
    setShowMemberForm(false);
  };

  const YesParentHandler = () => {
    setConfirmWindowIsShown(false);
    setShowMemberForm(true);
    setShowAddMemberButton(false);
    setParent(parentHolder);
  };

  const SaveMember = (newMember) => {
    addNewMember(newMember);
    setParentHolder(newMember);
    CloseAddMemberFormHandler();
    setConfirmWindowIsShown(true);
    setMemberID(newMember.memberID);
    changeStatus(newMember.memberID);
  };

  return (
    <div>
      {myUser && (
        <>
          <div>
            <div className="Members">
              {submitted && (
                <Fragment>
                  {myUser.email.endsWith("@tac-idwalalethu.com") && (
                    <Administrator />
                  )}
                  <FilterBar onValueChange={handleValueFromChild} />
                  {confirmWindowIsShown && (
                    <ConfirmWindowYes
                      firstMsg={"Here is your Membership ID "}
                      title={memberID}
                      name={"Do you have a "}
                      lastMsg={"child to Add? "}
                      onNo={hideConfirmWindowHandler}
                      onYes={YesParentHandler}
                    />
                  )}
                  {showMemberForm && (
                    <MemberForm
                      data={parent}
                      onConfirm={SaveMember}
                      onCancelMeal={CloseAddMemberFormHandler}
                    />
                  )}
                  {showAddMemberButton && (
                    <>
                      <button
                        className={classes.buttonAdd}
                        onClick={AddMemberHandler}
                      >
                        Add New Member
                      </button>
                      <AllMembers catalog={valueFromChild} />
                    </>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </>
      )}
      {!myUser && <LoginPage />}
    </div>
  );
};

export default Members;
