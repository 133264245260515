import React, { useState, useEffect } from "react";
import { database } from "../../LoginPage/firebase-config";
import { ref, get } from "firebase/database";
import "./TitheStatusTable.css"; // Ensure you have styles for your table

const TitheStatusTable = ({ selectedProvince, selectedBranch }) => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const membersRef = ref(database, "members");
        const snapshot = await get(membersRef);
        const data = snapshot.val() || {};

        const filteredMembers = Object.values(data).filter(
          (member) =>
            member.province === selectedProvince &&
            member.branch === selectedBranch
        );

        setMembers(filteredMembers);
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };

    if (selectedProvince && selectedBranch) {
      fetchMembers();
    }
  }, [selectedProvince, selectedBranch]);

  const getTitheStatusForMonth = (member, year, month) => {
    const titheEntries = member.tithe || [];
    const paidMonths = new Set();

    titheEntries.forEach((entry) => {
      if (entry.date) {
        const date = new Date(entry.date);
        const entryMonthYear = `${date.getFullYear()}-${date.getMonth() + 1}`;
        paidMonths.add(entryMonthYear);
      }
    });

    const monthYear = `${year}-${month}`;
    return paidMonths.has(monthYear) ? "yes" : "no";
  };

  const renderTable = () => {
    const year = 2024;
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return (
      <table className="tithe-status-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Known As</th>
            <th>Surname</th>
            {months.map((month, index) => (
              <th key={index}>
                {month} ({year})
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {members.map((member) => (
            <tr key={member.id}>
              <td>{member.title}</td>
              <td>{member.knownAs}</td>
              <td>{member.surname}</td>
              {months.map((month, index) => {
                const status = getTitheStatusForMonth(member, year, index + 1);
                return (
                  <td key={index} className={status === "yes" ? "yes" : "no"}>
                    {status}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <h2 class="h2_member2">Tithe Members Status</h2>
      {selectedProvince && selectedBranch && renderTable()}
    </div>
  );
};

export default TitheStatusTable;
