import React from "react";
import classes from "./ConfirmWindowYes.module.css";
import Modal from "../UI/Modal";

const ConfirmWindowYes = (props) => {
  const modalActions = (
    <div className={classes.actions}>
      <button className={classes.yes} onClick={props.onYes}>
        Yes
      </button>
      <button className={classes.no} onClick={props.onNo}>
        No
      </button>
    </div>
  );

  const cartModalContent = (
    <React.Fragment>
      <div className={classes.total}>
        <div>
          <div>
            {props.firstMsg}
            {props.title}
          </div>
          {props.name}
          {props.lastMsg}
        </div>
      </div>
      {modalActions}
    </React.Fragment>
  );

  return <Modal onClose={props.onClose}>{cartModalContent}</Modal>;
};

export default ConfirmWindowYes;
