import { ref, get } from "firebase/database";
import { database } from "../../../LoginPage/firebase-config";

export const fetchMemberDataById = async (id) => {
  try {
    const memberItemPath = `members/${id}`;
    const memberItemReference = ref(database, memberItemPath);
    const response = await get(memberItemReference);
    if (!response.exists()) {
      throw new Error("Member data not found");
    }
    const data = response.val();
    return data;
  } catch (error) {
    console.error("Error fetching member data:", error);
    throw error;
  }
};
