import React, { useState } from "react";
import styled from "styled-components";
import Button from "./components/Button";
import Icon from "./components/Icon";
import Input from "./components/Input";
import { FaTimes } from "react-icons/fa";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "./firebase-config";
import classes from "./LoginPage.module.css";
import { useGlobalContext } from "../store/context";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const { setWantToLogIn } = useGlobalContext();
  const FacebookBackground =
    "linear-gradient(to right, #0546A0 0%, #0546A0 40%, #663FB6 100%)";
  const InstagramBackground =
    "linear-gradient(to right, #A12AC4 0%, #ED586C 40%, #F0A853 100%)";
  const TwitterBackground =
    "linear-gradient(to right, #56C1E1 0%, #35A9CE 50%)";

  const [loginWithText, setLoginWithText] = useState("REGISTER");
  const [buttonText, setButtonText] = useState("LOGIN");
  const [registered, setRegistered] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [userRegisted, setUserRegisted] = useState(false);
  const [checkPassword, setCheckPassword] = useState(false);
  const [remainingChars, setRemainingChars] = useState(false);
  const [shortPassword, setShortPassword] = useState(false);
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isResetEmailSent, setIsResetEmailSent] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const LoadUserEmail = (event) => {
    {
      if (buttonText === "Sign Up") {
        setRegisterEmail(event.target.value);
      } else if (buttonText === "LOGIN") {
        setLoginEmail(event.target.value);
      }
    }
  };
  const LoadUserPassword = (event) => {
    const password = event.target.value;
    if (buttonText === "Sign Up") {
      setRegisterPassword(password);
    } else if (buttonText === "LOGIN") {
      setLoginPassword(password);
    }

    const remainingChars = 7 - password.length;
    if (remainingChars > 0 && remainingChars <= 7) {
      setRemainingChars(remainingChars);
    } else {
      setRemainingChars(null);
    }
  };

  const checkIfEmpty = () => {
    setUserRegisted(false);
    setIsResetEmailSent(false);
    let isRegisterEmpty = !registerEmail || !registerPassword;
    let isLoginEmpty = !loginEmail || !loginPassword;

    if (loginWithText === "REGISTER") {
      isRegisterEmpty = false;
    } else {
      isLoginEmpty = false;
    }

    if (isRegisterEmpty && isLoginEmpty) {
      setEmpty(true);
    } else if (isRegisterEmpty) {
      setEmpty(true);
    } else if (isLoginEmpty) {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
  };
  const register = async () => {
    setRegistered(false);
    checkIfEmpty();
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );
      setWantToLogIn(false);
    } catch (error) {
      if (error.message === "Firebase: Error (auth/email-already-in-use).") {
        setRegistered(true);
        /*    setLoginPassword(registerPassword);
        setLoginEmail(registerEmail);
        login(); */
      }
      if (
        error.message ===
        "Firebase: Password should be at least 6 characters (auth/weak-password)."
      ) {
        setCheckPassword(true);
      }
    }
  };

  const login = async () => {
    checkIfEmpty();
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      setWantToLogIn(false);
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        setUserRegisted(true); // Assuming setUserRegisted is a state updater function
      }
    }
  };

  const handleLoginToggle = () => {
    if (loginWithText === "OR LOGIN WITH") {
      setLoginWithText("REGISTER");
      setButtonText("LOGIN");
    } else {
      setLoginWithText("OR LOGIN WITH");
      setButtonText("Sign Up");
    }
  };
  const handleCloseLogIn = () => {
    setWantToLogIn(false);
    navigate("/");
  };

  const handleResetPassword = async () => {
    if (loginEmail) {
      try {
        await sendPasswordResetEmail(auth, loginEmail);
        setIsResetEmailSent(true);
        setError(null);
      } catch (error) {
        setIsResetEmailSent(false);
        setError(error.message);
      }
    } else {
    }
  };

  return (
    <MainContainer>
      <div className={classes.closeLogIn} onClick={handleCloseLogIn}>
        <FaTimes className={classes.closeTimes} />
      </div>
      {registered && <h4 className="email-registered">Already Registered</h4>}
      {empty && <h4 className="email-registered">Fill-in-the details</h4>}
      {checkPassword && (
        <h4 className="email-registered">The password is too shoot</h4>
      )}
      {remainingChars && remainingChars !== 8 && (
        <h4 className="email-registered">
          {`The password is ${remainingChars} character${
            remainingChars > 1 ? "s" : ""
          } short`}
        </h4>
      )}
      {shortPassword && (
        <h4 className="email-registered">The password is too shoot</h4>
      )}
      {userRegisted && (
        <h4 className="email-registered">You are not Registered</h4>
      )}
      {isResetEmailSent && (
        <h4 className="email-registered">
          Password reset email sent. Check your inbox.
        </h4>
      )}

      <WelcomeText>Welcome</WelcomeText>
      <InputContainer>
        <Input
          type="text"
          placeholder="Email"
          onChange={(event) => LoadUserEmail(event)}
          onBlur={(event) => LoadUserEmail(event)}
          onInput={(event) => LoadUserEmail(event)}
        />
        <Input
          type="password"
          placeholder="Password"
          onChange={(event) => LoadUserPassword(event)}
          onBlur={(event) => LoadUserPassword(event)}
          onInput={(event) => LoadUserPassword(event)}
        />
      </InputContainer>
      <ButtonContainer>
        <Button
          onClick={buttonText === "LOGIN" ? login : register}
          content={buttonText}
        />
      </ButtonContainer>
      <LoginWith onClick={handleLoginToggle}>{loginWithText}</LoginWith>
      <HorizontalRule />
      <ForgotPassword onClick={handleResetPassword}>
        Forgot Password ?
      </ForgotPassword>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  margin-top: 0rem;
  margin-left: 5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 30rem;
  width: 60rem;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  h4.email-registered {
    color: red;
    margin: 1rem 0 1rem 0;
    font-size: small;
  }

  @media only screen and (min-width: 80px) {
    width: 80vw;
    height: 60vh;
    margin-top: 0rem;
    margin-left: 2rem;
    hr {
      margin-bottom: 0.3rem;
    h4 {
      font-size: small;
    }
  }
  @media only screen and (max-width: 120px) {
    width: 20rem;
    height: 200vh;
    margin-top: 0rem;
    margin-left: 1rem;
    hr {
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: small;
    }
  }
  @media only screen and (max-width: 320px) {
    width: 350vw;
    height: 195vh;
    hr {
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 360px) {
    width: 80vw;
    height: 100vh;
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 411px) {
    width: 80vw;
    height: 100vh;
    margin-top: 0rem;
    margin-left: 2rem;
  }

  @media only screen and (min-width: 768px) {
    width: 80vw;
    height: 40vh;
    margin-top: 4rem;
    margin-left: 6rem;
  }
  @media only screen and (min-width: 1024px) {
    width: 70vw;
    height: 30vh;
    margin-top: 5rem;
    margin-left: 10rem;
  }
  @media only screen and (min-width: 1080x) {
    width: 70vw;
    height: 60vh;
    margin-top: -10rem;
    margin-left: 20rem;
  }
  @media only screen and (min-width: 1280px) {
    width: 30vw;
    height: 80vh;
    margin-top: 1rem;
    margin-left: 30rem;
  }
  @media only screen and (min-width: 1366px) {
    width: 30vw;
    height: 85vh;
    margin-top: -4rem;
    margin-left: 30rem;
  }
  @media only screen and (min-width: 1400px) {
    width: 30vw;
    height: 55vh;
    margin-top: rem;
    margin-left: 35rem;
  }
`;
const WelcomeText = styled.h2`
  margin: 1rem 0;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 20%;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin: 0.5rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginWith = styled.h5`
  cursor: pointer;
`;

const HorizontalRule = styled.hr`
  width: 90%;
  height: 0.3rem;
  border-radius: 0.8rem;
  border: none;
  background: linear-gradient(to right, #14163c 0%, #03217b 79%);
  background-color: #ebd0d0;
  margin: 1.5rem 0 1rem 0;
  backdrop-filter: blur(25px);
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 2rem 0 3rem 0;
  width: 100%;
`;

const ForgotPassword = styled.h4`
  cursor: pointer;
`;

export default LoginPage;
