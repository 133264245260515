import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "./PaymentsList.module.css"; // Update with your actual stylesheet path
import { useUserContext } from "../../store/user_context";

const PaymentProvince = () => {
  const navigate = useNavigate();
  const { myUser } = useUserContext();
  const [payments, setPayments] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("all"); // Initial state: all provinces
  const [selectedMonth, setSelectedMonth] = useState("all"); // Initial state: all months

  useEffect(() => {
    if (!myUser) {
      navigate("/unauthorizedpage");
    } else {
      console.log("Authentication check passed. Proceeding...");
    }
  }, [myUser, navigate]);

  useEffect(() => {
    const fetchPayments = async () => {
      // Fetch payments data from your API
      const apiEndpoint = process.env.REACT_APP_API_BASE_URL;
      const queryParams = new URLSearchParams();
      queryParams.append("auth", myUser.token);
      queryParams.append("email", myUser.email);
      queryParams.append("passphrase", myUser.passphrase);
      const response = await fetch(
        `${apiEndpoint}/payments.json?${queryParams}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch payments data");
        return;
      }

      const data = await response.json();

      if (data) {
        const paymentsArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        setPayments(paymentsArray);
      }
    };

    fetchPayments();
  }, []);

  const provinces = [...new Set(payments.map((payment) => payment.province))];
  const months = [
    ...new Set(payments.map((payment) => payment.date.split("-")[1])),
  ];

  const handleChangeProvince = (event) => {
    setSelectedProvince(event.target.value);
  };

  const handleChangeMonth = (event) => {
    setSelectedMonth(event.target.value);
  };

  const filteredPayments = payments.filter((payment) => {
    const matchProvince =
      selectedProvince === "all" || payment.province === selectedProvince;
    const matchMonth =
      selectedMonth === "all" || payment.date.split("-")[1] === selectedMonth;
    return matchProvince && matchMonth;
  });

  // Calculate the total amount
  const totalAmount = filteredPayments
    .reduce((total, payment) => {
      // Remove 'R' from the amount and parse the amount as float
      const amount = parseFloat(payment.amount.replace("R", ""));
      // Add the parsed amount to the total
      return total + amount;
    }, 0)
    .toFixed(2);

  return (
    <div className={classes.paymentsList}>
      <h2 className={classes.h2s}>Payment Province</h2>
      <div>
        <label className={classes.province}>Select Province:</label>
        <select
          id="province"
          value={selectedProvince}
          onChange={handleChangeProvince}
        >
          <option value="all">All</option>
          {provinces.map((province) => (
            <option key={province} value={province}>
              {province}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label className={classes.month}>Select Month:</label>
        <select id="month" value={selectedMonth} onChange={handleChangeMonth}>
          <option value="all">All</option>
          {months.map((month) => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </select>{" "}
      </div>
      <div className={classes.total}>Total Amount = {totalAmount}</div>

      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Title</th>
            <th>Name</th>
            <th>Cell</th>
            <th>Province</th>
            <th>Branch</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Slip</th>
          </tr>
        </thead>
        <tbody>
          {filteredPayments.map((payment) => (
            <tr key={payment.id}>
              <td style={style.tbody}>{payment.date}</td>
              <td style={style.tbody}>{payment.title}</td>
              <td style={style.tbody}>{payment.name}</td>
              <td style={style.tbody}>{payment.cell}</td>
              <td style={style.tbody}>{payment.province}</td>
              <td style={style.tbody}>{payment.branch}</td>
              <td style={style.tbody}>{payment.type}</td>
              <td style={style.tbody}>{payment.amount}</td>
              <td>
                <Link to={`/image/${payment.id}`} className={classes.linkCell}>
                  View
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <button onClick={() => navigate(-1)}>Go Back</button>
      </div>
    </div>
  );
};

// Define styles
const style = {
  tbody: {
    color: "white",
    padding: "10px",
  },
  linkCell: {
    // Add any additional styles you need
  },
};

export default PaymentProvince;
