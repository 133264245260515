import React, { useState } from "react";
import styles from "./SearchBar.module.css";

const SearchBar = ({ members }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [searchMessage, setSearchMessage] = useState("");

  const handleSearch = () => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    if (!members || !(members instanceof Map) || members.size === 0) {
      console.error("Members Map is undefined or empty");
      return;
    }

    const results = Array.from(members.values()).filter((member) => {
      const memberName = member.name ? member.name.toLowerCase() : "";
      const memberSurname = member.surname ? member.surname.toLowerCase() : "";
      const memberKnownAs = member.knownAs ? member.knownAs.toLowerCase() : "";

      return (
        memberName.includes(lowerCaseSearchTerm) ||
        memberSurname.includes(lowerCaseSearchTerm) ||
        memberKnownAs.includes(lowerCaseSearchTerm)
      );
    });

    setFilteredMembers(results);

    if (searchTerm.trim() === "") {
      setSearchMessage("Type or use ready message");
    } else {
      setSearchMessage("");
    }
  };

  return (
    <div className={styles.searchBarContainer}>
      <input
        type="text"
        placeholder="Search by name, surname, or knownAs"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={styles.searchInput}
      />
      <button
        onClick={handleSearch}
        className={`${styles.searchButton} ${
          searchTerm.trim() ? "" : styles.disabled
        }`}
        disabled={!searchTerm.trim()} // Disable button if searchTerm is empty
      >
        Search
      </button>
      <div className={styles.message}>
        {searchMessage && <p>{searchMessage}</p>}
      </div>
      {searchTerm.trim() && (
        <div className={styles.resultsContainer}>
          {filteredMembers.length > 0 ? (
            <table className={styles.resultsTable}>
              <thead className={styles.tableHeader}>
                <tr className={styles.table}>
                  <th className={styles.tableCell}>Name</th>
                  <th className={styles.tableCell}>Surname</th>
                  <th className={styles.tableCell}>Known As</th>
                  <th className={styles.tableCell}>Member ID</th>
                  <th className={styles.tableCell}>Province</th>
                </tr>
              </thead>
              <tbody>
                {filteredMembers.map((member) => (
                  <tr key={member.id} className={styles.tableRow}>
                    <td className={styles.intableCell}>{member.name}</td>
                    <td className={styles.intableCell}>{member.surname}</td>
                    <td className={styles.intableCell}>{member.knownAs}</td>
                    <td className={styles.intableCell}>{member.memberID}</td>
                    <td className={styles.intableCell}>{member.province}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className={styles.noResults}>No results found</p>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
