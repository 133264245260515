import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import FillInPassword from "./FillInPassword";
import classes from "./Administrator.module.css";
const Administrator = () => {
  const [correct, setCorrect] = useState(false);
  const [showQoution, setshowQoution] = useState(false);
  const verify = () => {
    setshowQoution(true);
  };

  return (
    <div>
      {!showQoution && (
        <button className={classes.adminBtn} onClick={verify}>
          LogIn as Adminstrator
        </button>
      )}
      {showQoution && <FillInPassword />}
    </div>
  );
};

export default Administrator;
