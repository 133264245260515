import React, { useState, useEffect } from "react";
import ActiveMembers from "./ActiveMembers";
import Calculated from "./Calculated";
import classes from "./AllMember.module.css";

const AllMember = ({ showNewMember, memberS }) => {
  const [branchCounts, setBranchCounts] = useState({});
  const [titleCounts, setTitleCounts] = useState({});
  const [sealedFalseCount, setSealedFalseCount] = useState(0);
  const [countMembers, setCountMembers] = useState(0);

  useEffect(() => {
    if (memberS.length === 0) {
      return;
    }

    const branchCounts = {};
    const titleCounts = {};
    let sealedFalseCount = 0;
    let countMembers = memberS.length;

    memberS.forEach((member) => {
      if (member.sealed === "no") {
        sealedFalseCount++;
      }

      branchCounts[member.branch] = (branchCounts[member.branch] || 0) + 1;
      titleCounts[member.title] = (titleCounts[member.title] || 0) + 1;
    });

    // Pass the calculated data to the parent component
    setBranchCounts(branchCounts);
    setTitleCounts(titleCounts);
    setSealedFalseCount(sealedFalseCount);
    setCountMembers(countMembers);
  }, [memberS]);

  return (
    <div className={classes.MemberCalculated}>
      <Calculated
        branchCounts={branchCounts}
        titleCounts={titleCounts}
        sealedFalseCount={sealedFalseCount}
        countMembers={countMembers}
      />
      <ActiveMembers showNewMember={showNewMember} memberS={memberS} />
    </div>
  );
};

export default AllMember;
