// AccountNumber.jsx

import React from "react";
import styles from "./AccountNumber.module.css"; // Ensure the path is correct

const AccountNumber = () => {
  const cloudinaryCloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  const imageUrl = `https://res.cloudinary.com/${cloudinaryCloudName}/image/upload/v1701881007/church/account_ttntna.jpg`;

  return (
    <div className={styles.container}>
      <h2>Account Number</h2>
      <img
        src={imageUrl}
        alt="Church Account Number"
        className={styles.image}
      />
    </div>
  );
};

export default AccountNumber;
