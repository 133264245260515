import React from "react";
import { useState } from "react";
import FillInPasswordOrders from "./FillInPasswordOrders";
const AdministratorOrders = () => {
  const [correct, setCorrect] = useState(false);
  const [showQoution, setshowQoution] = useState(false);
  const verify = () => {
    setshowQoution(true);
  };

  return (
    <div>
      {!showQoution && (
        <button onClick={verify}>LogIn as Administrator Order</button>
      )}
      {showQoution && <FillInPasswordOrders />}
    </div>
  );
};

export default AdministratorOrders;
