import React, { useEffect } from "react";
import classes from "./MemberList.module.css";
import MemberItem from "./MemberItem";

const MemberList = ({ members, onDataReceived }) => {
  useEffect(() => {
    const branchCounts = {};
    const titleCounts = {};
    let sealedFalseCount = 0;
    let countMembers = members.length;

    members.forEach((member) => {
      countMembers++;

      if (member.sealed === "no") {
        sealedFalseCount++;
      }

      titleCounts[member.title] = (titleCounts[member.title] || 0) + 1;
      branchCounts[member.branch] = (branchCounts[member.branch] || 0) + 1;
    });

    if (onDataReceived) {
      onDataReceived(branchCounts, titleCounts, sealedFalseCount, countMembers);
    }
  }, [onDataReceived]);

  return (
    <ul className={classes["expenses-list"]}>
      {members.map((member, index) => (
        <MemberItem key={member.id} {...member} />
      ))}
    </ul>
  );
};

export default MemberList;
