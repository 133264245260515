import React, { useState } from "react";
import Card from "../UI/MemberCard/Card";
import IdPhotoRound from "./IdPhotoRound";
import classes from "./MemberItem.module.css"; // Ensure correct import path for CSS
import { useGlobalContext } from "../../store/context";
import ShowCalender from "./ShowCalender";
import { useUserContext } from "../../store/user_context";

const MemberItem = (props) => {
  const { addTithe, cart } = useGlobalContext();
  const [startDate, setStartDate] = useState(new Date());
  const [amount, setAmount] = useState(0);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const { myUser } = useUserContext();
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns 0-based index
    const day = date.getDate();
    return `${year}-${month}-${day}`;
  };
  const titheHandler = (date, amount) => {
    setStartDate(date);
    setAmount(amount);
    setShowDatePicker(false);
    const formattedDate = formatDate(date);
    addTithe(props.id, formattedDate, amount, myUser.email);
  };

  const handlePaidClick = () => {
    setShowDatePicker(true);
  };

  return (
    <li>
      <Card className="member-item">
        <IdPhotoRound img={props.img} />
        <div className="expense-item__description">
          <div className="discription">
            <div>{props.title}</div>
            <h3>{props.knownAs}</h3>
            <h3>{props.surname}</h3>
            <div className={classes.price}>{props.branch}</div>
          </div>

          <div className="button_ud">
            <button className="member-item__pressent" onClick={handlePaidClick}>
              Paid
            </button>
          </div>

          {showDatePicker && (
            <ShowCalender
              startDate={startDate}
              amount={amount}
              onConfirm={(date, amount) => titheHandler(date, amount)}
              onCancel={() => setShowDatePicker(false)}
            />
          )}
        </div>
      </Card>
    </li>
  );
};

export default MemberItem;
