import { v4 as uuidv4 } from "uuid";
import {
  CLEAR_CART,
  REMOVE,
  REMOVE_MEMBER,
  INCREASE,
  DECREASE,
  DELETA,
  LOADING,
  DISPLAY_ITEMS,
  NEW_BRANCH_DATE,
  UPDATE_ATTENDANCE_RECORD,
  NOTIFICATION_DISPLAY,
  WARNING,
  REPLACE_MEMBERS_DATA,
  SUBMITING,
  FLAG,
  FLAGPROOF,
  BRANCH_NAME,
  RELOAD_MEMBERS,
  ADD_MEMBER,
  WANT_TO_LOG_IN,
  EDITMEMBER,
  ADDTITHE,
} from "./actions";

const reducer = (state, action) => {
  if (action.type === SUBMITING) {
    return { ...state, isSubmitting: action.payload.status };
  }
  if (action.type === FLAG) {
    return { ...state, flag: action.payload.value };
  }
  if (action.type === FLAGPROOF) {
    return { ...state, flagProof: action.payload.value };
  }
  if (action.type === WANT_TO_LOG_IN) {
    return { ...state, LogIn: action.payload.status };
  }
  if (action.type === CLEAR_CART) {
    return { ...state, cart: new Map() };
  }
  if (action.type === REMOVE) {
    const newCart = new Map(state.cart);
    newCart.delete(action.payload.id);
    return { ...state, cart: newCart };
  }
  if (action.type === ADDTITHE) {
    const newCart = new Map(state.cart); // Fetch new data
    const memberItemId = action.payload.id;
    const memberItem = newCart.get(memberItemId);

    // Ensure item exists before accessing its properties
    if (!memberItem) {
      console.error(`Item with ID ${memberItemId} not found in the cart.`);
      return state; // Return the current state if memberItem is not found
    }

    const newTitheEntry = {
      date: action.payload.date,
      amount: action.payload.amount,
      user: action.payload.user,
    };

    // Ensure the tithe property exists and is an array
    const newTithe = memberItem.tithe
      ? [...memberItem.tithe, newTitheEntry]
      : [newTitheEntry];

    const newMemberItems = {
      ...memberItem,
      status: "paid",
      tithe: newTithe,
    };

    newCart.set(memberItemId, newMemberItems);

    return {
      ...state,
      cart: newCart,
      newMember: newMemberItems,
    };
  }

  if (action.type === REPLACE_MEMBERS_DATA) {
    const newCartArray = Object.entries(action.payload.newCart).map(
      ([id, item]) => ({
        id,
        ...item,
      })
    );

    return {
      ...state,
      cart: new Map(newCartArray.map((item) => [item.id, item])),
    };
  }
  if (action.type === RELOAD_MEMBERS) {
    const newCart = new Map(state.cart);

    newCart.forEach((item) => {
      const updatedItem = {
        ...item,
        status: "null",
      };
      newCart.set(item.id, updatedItem);
    });

    return { ...state, cart: newCart };
  }
  if (action.type === ADD_MEMBER) {
    let uniqeNumber = uuidv4(); // Default to generating a new UUID

    if (action.payload.newMember.id) {
      uniqeNumber = action.payload.newMember.id; // Use provided ID if available
    }
    const newCart = new Map(state.cart);

    const memberItem = {
      id: uniqeNumber, // Generate a unique ID
      idNumber: action.payload.newMember.idNumber,
      memberID: action.payload.newMember.memberID,
      title: action.payload.newMember.title,
      name: action.payload.newMember.name,
      surname: action.payload.newMember.surname,
      knownAs: action.payload.newMember.knownAs,
      birthday: action.payload.newMember.birthday,
      race: action.payload.newMember.race,
      homeLanguage: action.payload.newMember.homeLanguage,
      img: action.payload.newMember.image,
      motherBranch: action.payload.newMember.motherBranch,
      branch: action.payload.newMember.branch,
      cell: action.payload.newMember.cell,
      cell2: action.payload.newMember.cell2,
      cellEmergency: action.payload.newMember.cellEmergency,
      emailAddress: action.payload.newMember.emailAddress,
      sealed: action.payload.newMember.sealed,
      serviceYears: action.payload.newMember.serviceYears,
      homePlace: action.payload.newMember.homePlace,
      province: action.payload.newMember.province,
      occupation: action.payload.newMember.occupation,
      active: "yes",
      amount: 0,
      value: "no",
      approvals: ["dummy"],
      tithe: ["dummy"],
      status: "null",
      attendance: ["dummy"],
      absent: ["dummy"],
    };

    newCart.set(memberItem.id, memberItem);

    return { ...state, cart: newCart, newMember: memberItem };
  }

  if (action.type === REMOVE_MEMBER) {
    const newCart = new Map(state.cart);
    const itemId = action.payload.id;
    const item = newCart.get(itemId);

    const newBranchs = new Map(state.branchs);
    const branchId = action.payload.BI;

    const branch = newBranchs.get(branchId);
    const date = action.payload.date;

    const newItem = {
      ...item,
      status: "null",
      attendance: item.attendance.filter((attender) => attender.date !== date), // Update attendance record
    };
    newCart.set(itemId, newItem);

    if (branch) {
      const attendanceRecord = branch.attendance.find(
        (attendance) => attendance.date === date
      );
      if (attendanceRecord) {
        const newBranch = {
          ...branch,
          attendance: branch.attendance.map((attendance) => {
            if (attendance.date === date) {
              return {
                ...attendance,
                total_attended: attendance.total_attended - 1, // Subtract 1 from total_attended
              };
            }

            return attendance;
          }),
        };

        newBranchs.set(branchId, newBranch); // Update the branch in the branchs Map
      }
    }

    return { ...state, cart: newCart, branchs: newBranchs };
  }

  if (action.type === INCREASE) {
    const newCart = new Map(state.cart);
    const itemId = action.payload.id;
    const item = newCart.get(itemId);

    const newBranchs = new Map(state.branchs);
    const branchId = action.payload.attendanceRecord.church_branch_id;
    const branch = newBranchs.get(branchId);

    const newItem = {
      ...item,

      status: "present",
      attendance: [...item.attendance, action.payload.attendanceRecord], // Update attendance record
    };
    newCart.set(itemId, newItem);
    if (branch) {
      const newBranch = {
        ...branch,
        attendance: branch.attendance.map((attendance) => {
          if (attendance.date === action.payload.attendanceRecord.date) {
            return {
              ...attendance,
              total_attended: attendance.total_attended
                ? attendance.total_attended + 1
                : 1,
            };
          }
          return attendance;
        }),
      };
      newBranchs.set(branchId, newBranch); // Update the branch in the branchs Map
    }

    return { ...state, cart: newCart, branchs: newBranchs };
  }
  if (action.type === DECREASE) {
    const newCart = new Map(state.cart);
    const itemId = action.payload.id;
    const item = newCart.get(itemId);

    const newBranchs = new Map(state.branchs);
    const branchId = action.payload.attendanceRecord.church_branch_id;
    const branch = newBranchs.get(branchId);

    const newItem = {
      ...item,

      status: "absent",
      absent: [...item.absent, action.payload.attendanceRecord], // Update attendance record
    };
    newCart.set(itemId, newItem);

    if (branch) {
      const newBranch = {
        ...branch,
        attendance: branch.attendance.map((attendance) => {
          if (attendance.date === action.payload.attendanceRecord.date) {
            return {
              ...attendance,
              total_absent: attendance.total_absent
                ? attendance.total_absent + 1
                : 1,
            };
          }
          return attendance;
        }),
      };
      newBranchs.set(branchId, newBranch); // Update the branch in the branchs Map
    }

    return { ...state, cart: newCart, branchs: newBranchs };
  }
  if (action.type === DELETA) {
    const newCart = new Map(state.cart);
    const itemId = action.payload.id;
    const item = newCart.get(itemId);

    const memberItem = {
      ...item,

      active: "no",
    };
    newCart.set(itemId, memberItem);
    return { ...state, cart: newCart, newMember: memberItem };
  }

  if (action.type === EDITMEMBER) {
    const newCart = new Map(state.cart);
    const itemId = action.payload.member.id;
    const item = newCart.get(itemId);
    const newItem = {
      ...item,

      title: action.payload.member.title,
      surname: action.payload.member.surname,
      cell: action.payload.member.cell,
      idNumber: action.payload.member.idNumber,
      img: action.payload.member.img,
      amount: action.payload.member.amount,
      active: action.payload.member.active,
      branch: action.payload.member.branch,
      sealed: action.payload.member.sealed,
      birthday: action.payload.member.birthday,
      serviceYears: action.payload.member.serviceYears,
      homePlace: action.payload.member.homePlace,
      status: action.payload.member.status,
    };
    newCart.set(itemId, newItem);

    return { ...state, cart: newCart };
  }

  if (action.type === LOADING) {
    return { ...state, loading: true };
  }
  if (action.type === UPDATE_ATTENDANCE_RECORD) {
    const newBranch_Date = action.payload.branch_Date;
    return { ...state, logging: false, branch_Date: newBranch_Date };
  }
  if (action.type === DISPLAY_ITEMS) {
    const newCart = new Map(action.payload.cart.map((item) => [item.id, item]));
    return { ...state, loading: false, cart: newCart };
  }
  if (action.type === NEW_BRANCH_DATE) {
    const newBranchs = new Map(state.branchs);
    const branchId = action.payload.attendanceRecord.church_branch_id;
    const branch = newBranchs.get(branchId);
    let My_Birthday = "1985-04-07";
    let isFirstTime = false;
    if (branch) {
      const date = action.payload.attendanceRecord.date;

      branch.attendance.forEach((record) => {
        if (record.date === date || date === My_Birthday) {
          isFirstTime = true;
          return;
        }
        return;
      });

      if (!isFirstTime) {
        const newBranch = {
          ...branch,
          attendance: [
            ...branch.attendance,
            {
              id: action.payload.attendanceRecord.date,
              date: action.payload.attendanceRecord.date,
              pastor_id: action.payload.attendanceRecord.pastor_id,
              total_attended: 0,
              total_absent: 0,
            },
          ],
        };

        newBranchs.set(branchId, newBranch);
      }
      return { ...state, branchs: newBranchs };
    }
  }
  if (action.type === NOTIFICATION_DISPLAY) {
    return {
      ...state,
      notification: {
        status: action.payload.status,
        title: action.payload.title,
        message: action.payload.message,
      },
    };
  }
  if (action.type === WARNING) {
    return {
      ...state,
      warning: {
        title: action.payload.title,
        heading: action.payload.heading,
        message: action.payload.message,
      },
    };
  }
  throw new Error(`no matching action type: ${action.type}`);
};
export default reducer;
