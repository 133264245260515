import { NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import classes from "./MainNavigation.module.css";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../assets/logo.png";
import LogInAndOut from "./Layout/LogInAndOut";

const MainNavigation = () => {
  const location = useLocation();
  const [click, setClick] = useState(false);
  useEffect(() => {
    setClick(false);
  }, [location]);
  const handleClick = () => setClick((prevState) => !prevState);

  return (
    <div className={classes.page}>
      <div className={classes.headerContainer}>
        <div className={classes.logo}>
          <img className={classes.logo} src={Logo} alt="logo" />
        </div>
        <h1 className={classes.mainheader}>
          The Apostolic Church
          <span className={classes.subtitle}> of idwala lethu</span>
        </h1>
      </div>
      <div className={classes.navbar}>
        <nav>
          <ul
            className={
              click ? `${classes.navMenu} ${classes.active}` : classes.navMenu
            }
          >
            <li className={classes.navItem}>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
                end
              >
                Home
              </NavLink>
            </li>
            <li className={classes.navItem}>
              <NavLink
                to="tithe"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
              >
                TITHE
              </NavLink>
            </li>
            <li className={classes.navItem}>
              <NavLink
                to="viewallmembers"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
              >
                CHURCH DATABASE
              </NavLink>
            </li>
            <li className={classes.navItem}>
              <NavLink
                to="cashbook"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
              >
                CASH BOOK
              </NavLink>
            </li>
            <li className={classes.navItem}>
              <NavLink
                to="SubmitProof"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
              >
                UPLOAD PROOF
              </NavLink>
            </li>
            <li className={classes.navItem}>
              <NavLink
                to="usbOrderForm"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
              >
                Qwa<sup>2</sup> USB OrderForm
              </NavLink>
            </li>
            <li className={classes.navItem}>
              <a
                href="https://books.zoho.com/portal/theapostolicchurch"
                className={classes.link}
              >
                CHURCH PORTAL
              </a>
            </li>
          </ul>
        </nav>
        <LogInAndOut></LogInAndOut>
        <div className={classes.hamburger} onClick={handleClick}>
          {click ? (
            <FaTimes className={classes.faTimes} />
          ) : (
            <FaBars className={classes.faBars} />
          )}
        </div>
      </div>
    </div>
  );
};

export default MainNavigation;
