import React, { useContext, useEffect, useState } from "react";
import { onAuthStateChanged, getAuth, updateProfile } from "firebase/auth";
import { auth } from "../LoginPage/firebase-config";

const UserContext = React.createContext();
const storedPassword = process.env.REACT_APP_PAYMENT_PASSWORD;

export const UserProvider = ({ children }) => {
  const [myUser, setMyUser] = useState(null);

  useEffect(() => {
    // onAuthStateChanged listener to update the user state
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        // If user is authenticated, include token in the user object
        currentUser.getIdToken().then((token) => {
          setMyUser({
            ...currentUser,
            token,
            email: currentUser.email,
            passphrase: "Anewn",
          });
        });
      } else {
        setMyUser(null); // If user is not authenticated, set user to null
      }
    });

    return () => {
      // Unsubscribe the onAuthStateChanged listener when the component unmounts
      unsubscribe();
    };
  }, []);

  const updateUserProfile = (user) => {
    updateProfile(getAuth().currentUser, {
      displayName: "radebe",
      photoURL: "https://example.com/jane-q-user/profile.jpg",
      tenantId: "5",
      level: 5,
    })
      .then(() => {
        console.log("User profile updated successfully.");
      })
      .catch((error) => {
        console.error("Error updating user profile:", error);
      });
  };

  return (
    <UserContext.Provider value={{ myUser, auth }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to access user context
export const useUserContext = () => {
  return useContext(UserContext);
};
