import React, { useState } from "react";
import classes from "./ShowCalender.module.css";
import Modal from "../UI/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ShowCalender = (props) => {
  const [selectedDate, setSelectedDate] = useState(props.startDate);
  const [amount, setAmount] = useState(props.amount);

  const confirmHandler = () => {
    props.onConfirm(selectedDate, amount);
  };

  const modalActions = (
    <div className={classes.actions}>
      <button className={classes.confirm} onClick={confirmHandler}>
        Confirm
      </button>
      <button className={classes.no} onClick={props.onCancel}>
        Cancel
      </button>
    </div>
  );

  const cartModalContent = (
    <React.Fragment>
      <div className={classes.total}>
        <div>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            inline
          />
        </div>
      </div>
      <div className={classes.amount}>
        <label htmlFor="amount">Amount</label>
        <input
          type="number"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>
      {modalActions}
    </React.Fragment>
  );

  return <Modal onClose={props.onCancel}>{cartModalContent}</Modal>;
};

export default ShowCalender;
