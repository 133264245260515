import { useEffect, useState } from "react";
import { useGlobalContext } from "./store/context";
import { onAuthStateChanged } from "firebase/auth";
import { auth, database } from "./LoginPage/firebase-config"; // Import auth and database from firebase-config.js
import Home from "./components/Home";
import RootLayout from "./components/Root";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import UnauthorizedPage from "./components/NotAuthorised/NotAuthorised";
import Error from "./components/Error";
import SubmitProof from "./components/DropBox/SubmitProof";
import PaymentsList from "./components/DropBox/PaymentList";
import ImageComponent from "./components/DropBox/ImageComponent";
import CashBook from "./components/CashBook/CashBook";
import PaymentProvince from "./components/DropBox/PaymentProvince";
import Database from "./components/UI/person/Database";
import UpdateMember from "./components/UI/UpdateMember/UpdateMember";
import { ref, set, get } from "firebase/database";
import Members from "./components/Meals/Members";
import Tithe from "./components/Tithe/Tithe";
import RecordTithe from "./components/Tithe/RecordTithe";
import TitheStatus from "./components/Tithe/TitheStatus";
import OrderForm from "./components/Oders/OrderForm";
import OrderList from "./components/Oders/OrderList ";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <Error />,
    children: [
      { index: true, element: <Home /> },
      { path: "unauthorizedpage", element: <UnauthorizedPage /> },
      { path: "SubmitProof", element: <SubmitProof /> },
      { path: "paymentprovince", element: <PaymentProvince /> },
      { path: "PaymentsList", element: <PaymentsList /> },
      { path: "/image/:id", element: <ImageComponent /> },
      { path: "cashbook", element: <CashBook /> },
      { path: "viewallmembers", element: <Members /> },
      { path: "churchDataBase", element: <Database /> },
      { path: "updateMember/:id", element: <UpdateMember /> },
      { path: "tithe", element: <Tithe /> },
      { path: "recordtithe", element: <RecordTithe /> },
      { path: "viewtithe", element: <TitheStatus /> },
      { path: "usbOrderForm", element: <OrderForm /> },
      { path: "usbOrderView", element: <OrderList /> },
      ,
    ],
  },
]);

function Application(props) {
  const [user, setUser] = useState({});
  const [data, setData] = useState({});
  const { setCartAtReducer, newMember } = useGlobalContext();
  useEffect(() => {
    // onAuthStateChanged listener to update the user state
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => {
      // Unsubscribe the onAuthStateChanged listener when the component unmounts
      unsubscribe();
    };
  }, []);

  //_______________loading_______Memeber_____________________
  const apiBaseUrl = process.env.REACT_APP_FIREBASE_DATABASE_URL;

  const fetchCartData = async () => {
    try {
      console.log("inside fetchCartData function");
      const response = await fetch(`${apiBaseUrl}/members.json`);

      if (!response.ok) {
        throw new Error("Could not fetch cart data!");
      }

      const data = await response.json();
      console.log("cart data Fetched success.........");
      // Filter out members where "active" is "no"
      const filteredMembers = Object.keys(data).reduce((acc, key) => {
        if (data[key].active !== "no") {
          acc[key] = data[key];
        }
        return acc;
      }, {});

      return filteredMembers || [];
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const Data = await fetchCartData();
      setCartAtReducer(Data);
    };

    fetchData();
  }, []);

  useEffect(() => {}, []);
  ////  _________________________________sending______members____
  useEffect(() => {
    const updateNewMemberInCart = async (newMember) => {
      try {
        // Ensure newMember does not contain undefined values
        const sanitizedNewMember = Object.fromEntries(
          Object.entries(newMember).filter(
            ([key, value]) => value !== undefined
          )
        );

        const cartItemPath = `members/${newMember.id}`;
        const cartItemReference = ref(database, cartItemPath);

        const cartItemSnapshot = await get(cartItemReference);

        if (cartItemSnapshot.exists()) {
          // Update the cart item data if it already exists
          await set(cartItemReference, {
            ...cartItemSnapshot.val(),
            ...sanitizedNewMember,
          });
          console.log("Cart item updated in Firebase");
        } else {
          // Save the entire sanitizedNewMember object to the cart item path
          await set(cartItemReference, sanitizedNewMember);
          console.log("Cart item added to Firebase");
        }
      } catch (error) {
        console.error(`Error updating/add cart item:`, error);
      }
    };

    updateNewMemberInCart(newMember);
  }, [newMember]);

  return (
    <main>
      <div>
        <ul>
          {Object.entries(data).map(([memberId, member]) => (
            <li key={memberId}>
              <h2>{member.name}</h2>
              <p>ID: {memberId}</p>
              <p>Email: {member.emailAddress}</p>
              {/* Add more details as needed */}
            </li>
          ))}
        </ul>
      </div>
      <RouterProvider router={router} />
    </main>
  );
}

export default Application;
