import React, { useState, useEffect } from "react";
import styles from "./FetchProvinceBranch.module.css";
import { useGlobalContext } from "../../../store/context";

const FetchProvinceBranch = ({ onContinue }) => {
  const [provinces, setProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branches, setBranches] = useState([]);
  const { cart } = useGlobalContext();

  useEffect(() => {
    const fetchProvincesAndBranches = () => {
      try {
        const members = cart
          ? Array.from(cart.entries()).map(([id, item]) => ({ id, ...item }))
          : [];

        // Extract unique provinces and branches from members
        const provinceData = members.reduce((acc, member) => {
          if (!acc[member.province]) {
            acc[member.province] = new Set();
          }
          acc[member.province].add(member.branch);
          return acc;
        }, {});

        const provincesArray = Object.keys(provinceData).map((province) => ({
          name: province,
          branches: Array.from(provinceData[province]),
        }));

        setProvinces(provincesArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProvincesAndBranches();
  }, [cart]);

  useEffect(() => {
    if (selectedProvince) {
      const branches =
        provinces.find((province) => province.name === selectedProvince)
          ?.branches || [];
      setBranches(branches);
    } else {
      setBranches([]);
    }
  }, [selectedProvince, provinces]);

  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value);
    setSelectedBranch(""); // Reset branch when province changes
  };

  const handleBranchChange = (event) => {
    setSelectedBranch(event.target.value);
    // Fetch members when branch is selected
    if (selectedProvince && event.target.value) {
      onContinue(selectedProvince, event.target.value);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.field}>
        <label className={styles.label} htmlFor="province">
          Select Province:
        </label>
        <select
          id="province"
          value={selectedProvince}
          onChange={handleProvinceChange}
          className={styles.select}
        >
          <option value="">-- Select Province --</option>
          {provinces.map((province) => (
            <option key={province.name} value={province.name}>
              {province.name}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.field}>
        <label className={styles.label} htmlFor="branch">
          Select Branch:
        </label>
        <select
          id="branch"
          value={selectedBranch}
          onChange={handleBranchChange}
          className={styles.select}
          disabled={!selectedProvince || branches.length === 0}
        >
          <option value="">-- Select Branch --</option>
          {branches.map((branch) => (
            <option key={branch} value={branch}>
              {branch}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default FetchProvinceBranch;
