import React, { useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import { database } from "../../LoginPage/firebase-config";
import styles from "./OrderList.module.css";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const OrderList = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const orderRef = ref(database, "usbOrders");

    // Fetch data from Firebase
    onValue(orderRef, (snapshot) => {
      const data = snapshot.val();
      const ordersList = [];
      let count = 0;
      for (let id in data) {
        count++;
        ordersList.push({ id, ...data[id], count });
      }
      setOrders(ordersList);
    });
  }, []);

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(orders);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Orders");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "orders.xlsx");
  };

  return (
    <div className={styles["order-list"]}>
      <h2>Order List</h2>

      {orders.length > 0 ? (
        <div>
          <button className={styles["download-button"]} onClick={downloadExcel}>
            Download Excel
          </button>
          <table className={styles["order-table"]}>
            <thead>
              <tr>
                <th>#</th>
                <th>Number of Copies</th>
                <th>Name</th>
                <th>Branch</th>
                <th>Phone</th>
                <th>Collect On</th>
                <th>Reference Number</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order.id} className={styles["order-item"]}>
                  <td>{order.count}</td>
                  <td>{order.numCopies}</td>
                  <td>{order.name}</td>
                  <td>{order.branch}</td>
                  <td>{order.phone}</td>
                  <td>{order.collectionDate}</td>
                  <td>{order.id}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No orders found.</p>
      )}
    </div>
  );
};

export default OrderList;
