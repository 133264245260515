import React from "react";
import MemberList from "./MemberList";
import classes from "./ActiveMembers.module.css";

const ActiveMembers = ({ showNewMember, memberS }) => {
  if (memberS.length === 0) {
    return <div>No members found.</div>;
  }

  return (
    <div className={classes.ActiveMembers}>
      <MemberList
        members={memberS.filter((member) =>
          showNewMember ? member.sealed === "no" : true
        )}
      />
    </div>
  );
};

export default ActiveMembers;
