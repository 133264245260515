import React from "react";
import classes from "./Catalog.module.css";

const Catalog = ({ categories, filterItems }) => {
  return (
    <div className={classes.dropdownContainer}>
      <select
        className={classes.filterDropdown}
        onChange={(e) => filterItems(e.target.value)}
      >
        {categories.map((category, index) => (
          <option key={index} value={category}>
            {category}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Catalog;
