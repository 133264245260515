import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "./PaymentsList.module.css";
import { useUserContext } from "../../store/user_context";

const PaymentsList = () => {
  const navigate = useNavigate();
  const { myUser } = useUserContext();
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    if (!myUser) {
      navigate("/unauthorizedpage");
    } else {
      console.log("Authentication check passed. Proceeding...");
      fetchPayments();
    }
  }, [myUser, navigate]);

  const fetchPayments = async () => {
    try {
      const apiEndpoint = process.env.REACT_APP_API_BASE_URL;

      const queryParams = new URLSearchParams();
      queryParams.append("auth", myUser.token);
      queryParams.append("email", myUser.email);
      queryParams.append("passphrase", myUser.passphrase);
      const response = await fetch(
        `${apiEndpoint}/payments.json?${queryParams}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch payments data");
        return;
      }

      const data = await response.json();

      if (data) {
        const paymentsArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        setPayments(paymentsArray);
      }
    } catch (error) {
      console.error("Error fetching payments:", error);
    }
  };

  return (
    <div className={classes.paymentsList}>
      <h2 className={classes.h2s}>Payments List</h2>
      <div>
        <button className={classes.goback} onClick={() => navigate(-1)}>
          Go Back
        </button>
        <Link className={classes.provinceLink} to="/paymentprovince">
          Filter by Province
        </Link>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Title</th>
            <th>Name</th>
            <th>Cell</th>
            <th>Province</th>
            <th>Branch</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Slip</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => (
            <tr key={payment.id}>
              <td style={style.tbody}>{payment.id}</td>
              <td style={style.tbody}>{payment.date}</td>
              <td style={style.tbody}>{payment.title}</td>
              <td style={style.tbody}>{payment.name}</td>
              <td style={style.tbody}>{payment.cell}</td>
              <td style={style.tbody}>{payment.province}</td>
              <td style={style.tbody}>{payment.branch}</td>
              <td style={style.tbody}>{payment.type}</td>
              <td style={style.tbody}>{payment.amount}</td>
              <td>
                <Link to={`/image/${payment.id}`} className={classes.linkCell}>
                  View
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Define styles
const style = {
  tbody: {
    color: "white",
    padding: "10px",
  },
  linkCell: {
    // Add any additional styles you need
  },
};

export default PaymentsList;
