import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchMemberDataById } from "./memberApi";
import UpdateMemberForm from "./UpdateMemberForm";
import { useGlobalContext } from "../../../store/context";

const UpdateMember = () => {
  const { id } = useParams();
  const [memberData, setMemberData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { addNewMember } = useGlobalContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchMemberDataById(id);
        setMemberData(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleUpdate = (updatedData) => {
    addNewMember(updatedData);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return memberData ? (
    <UpdateMemberForm data={memberData} onUpdate={handleUpdate} />
  ) : (
    <p>Member not found</p>
  );
};

export default UpdateMember;
