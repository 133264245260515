import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./UpdateMemberForm.css";
import classes from "./UpdateMemberForm.module.css";
import ImageUpload from "../../Meals/Data/ImageUpload";
import { useGlobalContext } from "../../../store/context";
import Warning from "../Warning";
const isEmpty = (value) => value.trim() === "";
const isTenChars = (value) => /^\d{10}$/.test(value.trim());
const UpdateMemberForm = ({ data, onUpdate }) => {
  const { setFlag, flag } = useGlobalContext();
  const [showWarningSmg, setShowWarningSmg] = useState(false);

  const [updatedData, setUpdatedData] = useState("");
  const [name, setName] = useState(data.name || "");
  const [title, setTitle] = useState(data.title || "");
  const [surname, setSurname] = useState(data.surname || "");
  const [knownAs, setKnownAs] = useState(data.knownAs || "");
  const [cell, setCell] = useState(data.cell || "");
  const [idNumber, setTIdNumber] = useState(data.idNumber || "");
  const [motherBranch, setMotherBranch] = useState(data.motherBranch || "");
  const [branch, setbranch] = useState(data.branch || "");
  const [occupation, setOccupation] = useState(data.occupation || "");
  const [sealed, setSealed] = useState(data.sealed || "");
  const [serviceYears, setServiceYears] = useState(data.serviceYears || "");
  const [homePlace, setHomePlace] = useState(data.homePlace || "");
  const [race, setRace] = useState(data.race || "");
  const [homeLanguage, setHomeLanguage] = useState(data.homeLanguage || "");
  const [province, setProvince] = useState(data.province || "");
  const [cell2, setCell2] = useState(data.cell2 || "");
  const [cellEmergency, setCellEmergency] = useState(data.cellEmergency || "");
  const [emailAddress, setEmailAddress] = useState(data.emailAddress || "");
  const [referenceNumber, setReferenceNumber] = useState(data.memberID || "");
  const [imageInputRef, setImageInputRef] = useState(data.img || "");

  const [year, month, day] = data.birthday.split("-");

  const [enteredBDay, setEnteredBDay] = useState(day || "");
  const [enteredBMonth, setEnteredBMonth] = useState(month || "");
  const [enteredBYear, setEnteredBYear] = useState(year || "");

  const [formInputsValidity, setFormInputsValidity] = useState({
    name: true,
    surname: true,
    knownAs: true,
    serviceYears: true,
    birthday: true,
    title: true,
    motherBranch: true,
    branch: true,
    sealed: true,
    cell: true,
    idNumber: true,
    homePlace: true,
    image: true,
    province: true,
    race: true,
    homeLanguage: true,
    occupation: true,
    cell2: true,
    cellEmergency: true,
    emailAddress: true,
  });

  const nameInputRef = useRef();
  const surnameInputRef = useRef();
  const knownAsInputRef = useRef();
  const serviceYearsInputRef = useRef();
  const daySelectRef = useRef(); // Add daySelectRef
  const monthSelectRef = useRef(); // Add monthSelectRef
  const yearSelectRef = useRef(); // Add yearSelectRef
  const titleInputRef = useRef();
  const motherBranchInputRef = useRef();
  const branchInputRef = useRef();
  const sealedInputRef = useRef();
  const cellInputRef = useRef();
  const idNumberInputRef = useRef();
  const homePlaceInputRef = useRef();
  const raceRef = useRef();
  const provinceInputRef = useRef();
  const homeLanguageInputRef = useRef();
  const cell2InputRef = useRef();
  const emailAddressInputRef = useRef();
  const occupationRef = useRef();
  const cellEmergencyInputRef = useRef();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    const enteredName = nameInputRef.current.value;
    const enteredSurname = surnameInputRef.current.value;
    const enteredKnownAs = knownAsInputRef.current.value;
    const enteredserviceYears = serviceYearsInputRef.current.value;
    const enteredDay = daySelectRef.current?.value || "";
    const enteredMonth = monthSelectRef.current?.value || "";
    const enteredYear = yearSelectRef.current?.value || "";
    const enteredtitle = titleInputRef.current.value;
    const enteredMotherBranch = motherBranchInputRef.current.value;
    const enteredbranch = branchInputRef.current.value;
    const enteredsealed = sealedInputRef.current.value;
    const enteredCell = cellInputRef.current.value;
    const enteredIdNumber = idNumberInputRef.current.value;
    const enteredHomePlace = homePlaceInputRef.current.value;
    const enteredImage = imageInputRef;
    const enteredRace = raceRef.current.value;
    const enteredProvince = provinceInputRef.current.value;
    const enteredHomeLanguage = homeLanguageInputRef.current.value;
    const enteredCell2 = cell2InputRef.current.value;
    const enteredEmailAddress = emailAddressInputRef.current.value;
    const enteredOccupation = occupationRef.current.value;
    const enteredCellEmergency = cellEmergencyInputRef.current.value;

    const enteredNameIsValid = !isEmpty(enteredName);
    const enteredSurnameIsValid = !isEmpty(enteredSurname);
    const enteredKnownAsIsValid = !isEmpty(enteredKnownAs);
    const enteredserviceYearsIsValid = true; // Not Mandetory
    const enteredbirthdayIsValid =
      enteredDay !== "" && enteredMonth !== "" && enteredYear !== "";

    const enteredtitleIsValid = !isEmpty(enteredtitle);
    const enteredMotherBranchIsValid = true; // Not Mandetory
    const enteredbranchIsValid = !isEmpty(enteredbranch);
    const enteredsealedIsValid = !isEmpty(enteredsealed);
    const enteredCellIsValid = true; // Not Mandetory
    const enteredIdNumberIsValid = true; // Not Mandetory
    const enteredHomePlaceIsValid = true; // Not Mandetory
    const enteredCell2IsValid = true; // Not Mandetory
    const enteredEmailAddressIsValid = true; // Not Mandetory
    const enteredOccupationIsValid = true; // Not Mandetory
    const enteredImageIsValid = true; // Not Mandetory
    const enteredRaceIsValid = !isEmpty(enteredRace);
    const enteredHomeLanguageIsValid = !isEmpty(enteredHomeLanguage);
    const enteredProvinceIsValid = !isEmpty(enteredProvince);
    const enteredCellEmergencyIsValid = !isEmpty(enteredCellEmergency);

    setFormInputsValidity({
      name: enteredNameIsValid,
      surname: enteredSurnameIsValid,
      knownAs: enteredKnownAsIsValid,
      image: enteredImageIsValid,
      birthday: enteredbirthdayIsValid,
      title: enteredtitleIsValid,
      motherBranch: enteredMotherBranchIsValid,
      branch: enteredbranchIsValid,
      sealed: enteredsealedIsValid,
      cell: enteredCellIsValid,
      serviceYears: enteredserviceYearsIsValid,
      idNumber: enteredIdNumberIsValid,
      homePlace: enteredHomePlaceIsValid,
      cell2: enteredCell2IsValid,
      emailAddress: enteredEmailAddressIsValid,
      occupation: enteredOccupationIsValid,
      race: enteredRaceIsValid,
      homeLanguage: enteredHomeLanguageIsValid,
      province: enteredProvinceIsValid,
      cellEmergency: enteredCellEmergencyIsValid,
    });

    const formIsValid =
      enteredNameIsValid &&
      enteredSurnameIsValid &&
      enteredKnownAsIsValid &&
      enteredImageIsValid &&
      enteredbirthdayIsValid &&
      enteredtitleIsValid &&
      enteredbranchIsValid &&
      enteredMotherBranchIsValid &&
      enteredsealedIsValid &&
      enteredCellIsValid &&
      enteredserviceYearsIsValid &&
      enteredIdNumberIsValid &&
      enteredHomePlaceIsValid &&
      enteredRaceIsValid &&
      enteredHomeLanguageIsValid &&
      enteredProvinceIsValid &&
      enteredCell2IsValid &&
      enteredEmailAddressIsValid &&
      enteredCellEmergencyIsValid;

    if (!formIsValid) {
      return;
    }
    const formattedBirthday = `${enteredYear}-${enteredMonth}-${enteredDay}`;
    const updatedData_1 = {
      ...data,
      memberID: referenceNumber,
      name: enteredName,
      surname: enteredSurname,
      knownAs: enteredKnownAs,
      image: enteredImage,
      birthday: formattedBirthday,
      title: enteredtitle,
      motherBranch: enteredMotherBranch,
      branch: enteredbranch,
      sealed: enteredsealed,
      cell: enteredCell,
      serviceYears: enteredserviceYears,
      idNumber: enteredIdNumber,
      homePlace: enteredHomePlace,
      race: enteredRace,
      homeLanguage: enteredHomeLanguage,
      province: enteredProvince,
      cell2: enteredCell2,
      emailAddress: enteredEmailAddress,
      occupation: enteredOccupation,
      cellEmergency: enteredCellEmergency,
    };

    setUpdatedData(updatedData_1);

    if (flag) {
      setShowWarningSmg(true);
    } else {
      handleFinalSubmit(updatedData_1);
    }
  };

  const handleFinalSubmit = (updatedData_1) => {
    onUpdate(updatedData_1);
    navigate("/churchDataBase");
  };

  const handleWarningClose = (status) => {
    setShowWarningSmg(false);
    if (status) {
      setFlag(false);
      handleFinalSubmit(updatedData);
    }
  };

  const nameControlClasses = `${classes.control} ${
    formInputsValidity.name ? "" : classes.invalid
  }`;
  const surnameControlClasses = `${classes.control} ${
    formInputsValidity.surname ? "" : classes.invalid
  }`;
  const knownAsControlClasses = `${classes.control} ${
    formInputsValidity.knownAs ? "" : classes.invalid
  }`;
  const serviceYearsControlClasses = `${classes.control} ${
    formInputsValidity.serviceYears ? "" : classes.invalid
  }`;
  const birthdayControlClasses = `${classes.control} ${
    formInputsValidity.birthday ? "" : classes.invalid
  }`;
  const titleControlClasses = `${classes.control} ${
    formInputsValidity.title ? "" : classes.invalid
  }`;
  const MotherbranchControlClasses = `${classes.control} ${
    formInputsValidity.motherBranch ? "" : classes.invalid
  }`;
  const branchControlClasses = `${classes.control} ${
    formInputsValidity.branch ? "" : classes.invalid
  }`;
  const sealedControlClasses = `${classes.control} ${
    formInputsValidity.sealed ? "" : classes.invalid
  }`;

  const emailAddressControlClasses = `${classes.control} ${
    formInputsValidity.cell ? "" : classes.invalid
  }`;
  const cellControlClasses = `${classes.control} ${
    formInputsValidity.cell ? "" : classes.invalid
  }`;
  const cell2ControlClasses = `${classes.control} ${
    formInputsValidity.cell ? "" : classes.invalid
  }`;
  const cellEmergencyControlClasses = `${classes.control} ${
    formInputsValidity.cell ? "" : classes.invalid
  }`;

  const idNumberControlClasses = `${classes.control} ${
    formInputsValidity.idNumber ? "" : classes.invalid
  }`;
  const homePlaceControlClasses = `${classes.control} ${
    formInputsValidity.homePlace ? "" : classes.invalid
  }`;

  const imageControlClasses = `${classes.control} ${
    formInputsValidity.image ? "" : classes.invalid
  }`;
  const provinceControlClasses = `${classes.control} ${
    formInputsValidity.province ? "" : classes.invalid
  }`;

  const raceControlClasses = `${classes.control} ${
    formInputsValidity.race ? "" : classes.invalid
  }`;
  const occupationControlClasses = `${classes.control} ${
    formInputsValidity.occupation ? "" : classes.invalid
  }`;

  const homeLanguageControlClasses = `${classes.control} ${
    formInputsValidity.homeLanguage ? "" : classes.invalid
  }`;

  const imageUploadHandler = (imageURL) => {
    setImageInputRef(imageURL);
  };
  // Generate an array of numbers from 1 to 31 for days
  const dayOptions = Array.from({ length: 31 }, (_, index) => index + 1);

  // Array of months
  const monthOptions = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Calculate current year
  const currentYear = new Date().getFullYear();

  // Array of years (dynamic based on current year)
  const yearOptions = Array.from(
    { length: 100 },
    (_, index) => currentYear - index
  );
  const onReferenceHandler = (ref) => {
    // Introduce a delay of 1000 milliseconds (1 second)
    setTimeout(() => {
      setReferenceNumber(ref);
    }, 1000);
    setTimeout(() => {}, 1000);
  };
  return (
    <div>
      {showWarningSmg && <Warning onClose={handleWarningClose} />}
      <form className={classes.forma} onSubmit={handleSubmit}>
        <div className={classes.memberNumber}>{referenceNumber}</div>
        <div className={classes.imageContainera}>
          <img
            src={imageInputRef}
            alt="Your Image"
            className={classes.imagea}
          />
        </div>

        <ImageUpload onImageUpload={imageUploadHandler} />

        <div className={birthdayControlClasses}>
          <label htmlFor="birthday">Birthday</label>

          <select
            id="day"
            ref={daySelectRef}
            value={enteredBDay}
            onChange={(e) => setEnteredBDay(e.target.value)}
          >
            <option value="" disabled>
              Day
            </option>
            {dayOptions.map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>

          <select
            id="month"
            ref={monthSelectRef}
            value={enteredBMonth}
            onChange={(e) => setEnteredBMonth(e.target.value)}
          >
            <option value="" disabled>
              Month
            </option>
            {monthOptions.map((month, index) => (
              <option key={index + 1} value={index + 1}>
                {month}
              </option>
            ))}
          </select>

          <select
            id="year"
            ref={yearSelectRef}
            value={enteredBYear}
            onChange={(e) => setEnteredBYear(e.target.value)}
          >
            <option value="" disabled>
              Year
            </option>
            {yearOptions.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>

          {!formInputsValidity.birthday && (
            <p className={classes.redParagraph}>
              Please enter a valid birthday!
            </p>
          )}
        </div>
        <div className={nameControlClasses}>
          <label htmlFor="fullName">Full Names</label>
          <input
            type="text"
            id="fullName"
            ref={nameInputRef}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          {!formInputsValidity.name && (
            <p className={classes.redParagraph}>
              Please enter valid full names!
            </p>
          )}
        </div>
        <div className={surnameControlClasses}>
          <label htmlFor="surname">Surname</label>
          <input
            type="text"
            id="surname"
            ref={surnameInputRef}
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
          {!formInputsValidity.surname && (
            <p className={classes.redParagraph}>
              Please enter valid full surname!
            </p>
          )}
        </div>

        <div className={knownAsControlClasses}>
          <label htmlFor="knownAs">Known As : name</label>
          <input
            type="text"
            id="knownAs"
            ref={knownAsInputRef}
            value={knownAs}
            onChange={(e) => setKnownAs(e.target.value)}
          />
          {!formInputsValidity.knownAs && (
            <p className={classes.redParagraph}>
              Please enter valid full knownAs Name!
            </p>
          )}
        </div>

        <div className={serviceYearsControlClasses}>
          <label htmlFor="serviceYears">
            Select a year you Joined the Church
          </label>
          <div className="custom-select">
            <select
              id="serviceYears"
              ref={serviceYearsInputRef}
              value={serviceYears}
              onChange={(e) => setServiceYears(e.target.value)}
            >
              <option value="" disabled>
                Select the Year
              </option>
              <option
                value="bornInChurch"
                onClick={() => setServiceYears("bornInChurch")}
              >
                Born in church
              </option>
              {yearOptions.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>

            <div className="arrow"></div>
          </div>
          {!formInputsValidity.serviceYears && (
            <p className={classes.redParagraph}>
              Select a year you Joined the Church
            </p>
          )}
        </div>

        <div className={cellControlClasses}>
          <label htmlFor="cell">Cell Number</label>
          <input
            type="text"
            id="cell"
            ref={cellInputRef}
            value={cell}
            placeholder="Optional"
            onChange={(e) => setCell(e.target.value)}
          />
          {!formInputsValidity.cell && (
            <p className={classes.redParagraph}>
              Please enter a valid Cell Phone Number!
            </p>
          )}
        </div>
        <div className={cell2ControlClasses}>
          <label htmlFor="cell2">Alternative Cell Number</label>
          <input
            type="text"
            id="cell2"
            ref={cell2InputRef}
            value={cell2}
            placeholder="Optional"
            onChange={(e) => setCell2(e.target.value)}
          />
          {!formInputsValidity.cell2 && (
            <p className={classes.redParagraph}>
              Please enter a valid Alternative Cell Phone Number!
            </p>
          )}
        </div>
        <div className={cellEmergencyControlClasses}>
          <label htmlFor="cellEmergency">Emergency Contact Cell Number</label>
          <input
            type="text"
            id="cellEmergency"
            ref={cellEmergencyInputRef}
            value={cellEmergency}
            onChange={(e) => setCellEmergency(e.target.value)}
          />
          {!formInputsValidity.cellEmergency && (
            <p className={classes.redParagraph}>
              Please enter a valid Alternative Emergency Cell Phone Number!
            </p>
          )}
        </div>
        <div className={emailAddressControlClasses}>
          <label htmlFor="emailAddress">Email Address</label>
          <input
            type="text"
            id="emailAddress"
            ref={emailAddressInputRef}
            value={emailAddress}
            placeholder="Optional"
            onChange={(e) => setEmailAddress(e.target.value)}
          />
          {!formInputsValidity.emailAddress && (
            <p className={classes.redParagraph}>
              Please enter a valid Email Address!
            </p>
          )}
        </div>
        <div className={occupationControlClasses}>
          <label htmlFor="occupation">Occupation</label>
          <div className="custom-select">
            <select
              type="text"
              id="occupation"
              ref={occupationRef}
              value={occupation}
              onChange={(e) => setOccupation(e.target.value)}
            >
              <option value="">Employed/Unemployed/Student</option>
              <option value="Employed">Employed</option>
              <option value="Unemployed">Unemployed</option>
              <option value="Student">Student</option>
              <option value="SelfEmployed">SelfEmployed</option>
              <option value="Child">Child</option>
              <option value="Other">Other</option>
            </select>
            <div className="arrow"></div>
          </div>
          {!formInputsValidity.race && (
            <p className={classes.redParagraph}>Please select a race!</p>
          )}
        </div>
        <div className={idNumberControlClasses}>
          <label htmlFor="idNumber">ID Number</label>
          <input
            type="text"
            id="idNumber"
            ref={idNumberInputRef}
            placeholder="Optional"
            value={idNumber}
            onChange={(e) => setTIdNumber(e.target.value)}
          />
          {!formInputsValidity.idNumber && (
            <p className={classes.redParagraph}>
              Please enter a valid ID number!
            </p>
          )}
        </div>
        <div className={homePlaceControlClasses}>
          <label htmlFor="homePlace">Your Born Home Place</label>
          <input
            type="text"
            id="homePlace"
            ref={homePlaceInputRef}
            value={homePlace}
            onChange={(e) => setHomePlace(e.target.value)}
          />
          {!formInputsValidity.homePlace && (
            <p className={classes.redParagraph}>
              Please enter a valid HomePlace!
            </p>
          )}
        </div>
        <div className={titleControlClasses}>
          <label htmlFor="title">Title</label>
          <div className="custom-select">
            <select
              type="text"
              id="title"
              ref={titleInputRef}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            >
              <option value="">Select Title</option>
              <option value="Sister">Sister</option>
              <option value="Brother">Brother</option>
              <option value="Deacon">Deacon</option>
              <option value="Ma-Deacon">Ma-Deacon</option>
              <option value="Priest">Priest</option>
              <option value="Ma-Priest">Ma-Priest</option>
              <option value="Ouster">Ouster</option>
              <option value="Ma-Ouster">Ma-Ouster</option>
              <option value="District">District</option>
              <option value="Ma-District">Ma-District</option>
              <option value="Overseer">Overseer</option>
              <option value="Ma-Overseer">Ma-Overseer</option>
              <option value="Overseer">Prophet</option>
              <option value="Ma-Overseer">Ma-Pophet</option>
              <option value="Apostle">Apostle</option>
              <option value="Ma-Apostle">Ma-Apostle</option>
            </select>
            <div className="arrow"></div>
          </div>
          {!formInputsValidity.title && (
            <p className={classes.redParagraph}>
              Please select a valid option for the title!
            </p>
          )}
        </div>
        <div className={MotherbranchControlClasses}>
          <label htmlFor="postal">Mother Branch [ Big Branch ]</label>
          <input
            type="text"
            id="Motherbranch"
            ref={motherBranchInputRef}
            value={motherBranch}
            placeholder="Optional"
            onChange={(e) => setMotherBranch(e.target.value)}
          />
          {!formInputsValidity.motherBranch && (
            <p className={classes.redParagraph}>
              Please enter your Mother Branch!
            </p>
          )}
        </div>
        <div className={branchControlClasses}>
          <label htmlFor="postal">Branch</label>
          <input
            type="text"
            id="branch"
            ref={branchInputRef}
            value={branch}
            onChange={(e) => setbranch(e.target.value)}
          />
          {!formInputsValidity.branch && (
            <p className={classes.redParagraph}>Please enter your!</p>
          )}
        </div>
        <div className={raceControlClasses}>
          <label htmlFor="race">Race</label>
          <div className="custom-select">
            <select
              id="race"
              ref={raceRef}
              value={race}
              onChange={(e) => setRace(e.target.value)}
            >
              <option value="">Select your Race</option>
              <option value="Black">Black</option>
              <option value="White">White</option>
              <option value="Coloured">Coloured</option>
              <option value="Indian">Indian</option>
            </select>
            <div className="arrow"></div>
          </div>
          {!formInputsValidity.race && (
            <p className={classes.redParagraph}>Please select a race!</p>
          )}
        </div>
        <div className={homeLanguageControlClasses}>
          <label htmlFor="homeLanguage">Home Language</label>
          <div className="custom-select">
            <select
              id="homeLanguage"
              ref={homeLanguageInputRef}
              value={homeLanguage}
              onChange={(e) => setHomeLanguage(e.target.value)}
            >
              <option value="">Select your Home Language</option>
              <option value="Afrikaans">Afrikaans</option>
              <option value="English">English</option>
              <option value="isiNdebele">isiNdebele</option>
              <option value="isiXhosa">isiXhosa</option>
              <option value="isiZulu">isiZulu</option>
              <option value="Sesotho">Sesotho</option>
              <option value="Setswana">Setswana</option>
              <option value="siSwati">siSwati</option>
              <option value="Tshivenda">Tshivenda</option>
              <option value="Xitsonga">Xitsonga</option>
              <option value="Sepedi">Sepedi</option>
            </select>
            <div className="arrow"></div>
          </div>
          {!formInputsValidity.homeLanguage && (
            <p className={classes.redParagraph}>
              Please select a Home Language!
            </p>
          )}
        </div>

        <div className={provinceControlClasses}>
          <label htmlFor="province">Province</label>
          <div className="custom-select">
            <select
              id="province"
              ref={provinceInputRef}
              value={province}
              onChange={(e) => setProvince(e.target.value)}
            >
              <option value="">Select Province / Country</option>
              <option value="Eastern Cape">Eastern Cape</option>
              <option value="Free State">Free State</option>
              <option value="Gauteng">Gauteng</option>
              <option value="KwaZulu-Natal">KwaZulu-Natal</option>
              <option value="Limpopo">Limpopo</option>
              <option value="Mpumalanga">Mpumalanga</option>
              <option value="North West">North West</option>
              <option value="Northern Cape">Northern Cape</option>
              <option value="Western Cape">Western Cape</option>
              <option value="Lesotho">Lesotho</option>
              <option value="Mozambique">Mozambique</option>
              <option value="Zimbabwe">Zimbabwe</option>
            </select>
            <div className="arrow"></div>
          </div>
          {!formInputsValidity.province && (
            <p className={classes.redParagraph}>Please select a Province!</p>
          )}
        </div>
        <div className={sealedControlClasses}>
          <label htmlFor="sealed">Sealed Status</label>
          <input
            type="text"
            id="sealed"
            ref={sealedInputRef}
            value={sealed}
            onChange={(e) => setSealed(e.target.value)}
          />
          {!formInputsValidity.sealed && (
            <p className={classes.redParagraph}>Please enter sealed status!</p>
          )}
        </div>
        <div className={classes.actions}>
          <button type="submit">Update Member</button>
          <Link to="/churchDataBase">
            <button type="button">Cancel</button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default UpdateMemberForm;
