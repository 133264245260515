import React, { useState, useEffect } from "react";
import styles from "./ChooseProvinceBranch.module.css";
import { database } from "../../LoginPage/firebase-config";
import { ref, get } from "firebase/database";

const ChooseProvinceBranchView = ({ onContinue }) => {
  const [provinces, setProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");

  useEffect(() => {
    const fetchProvincesAndBranches = async () => {
      try {
        const provincesRef = ref(database, "provinces");
        const snapshot = await get(provincesRef);
        const data = snapshot.val();
        setProvinces(data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProvincesAndBranches();
  }, []);

  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value);
    setSelectedBranch(""); // Reset branch when province changes
  };

  const handleBranchChange = (event) => {
    setSelectedBranch(event.target.value);
  };

  useEffect(() => {
    if (selectedProvince && selectedBranch) {
      onContinue(selectedProvince, selectedBranch);
    }
  }, [selectedProvince, selectedBranch, onContinue]);

  const filteredBranches = selectedProvince
    ? provinces.find((province) => province.name === selectedProvince)
        ?.branches || []
    : [];

  return (
    <div className={styles.container}>
      <div className={styles.field}>
        <label className={styles.label} htmlFor="province">
          Select Province:
        </label>
        <select
          id="province"
          value={selectedProvince}
          onChange={handleProvinceChange}
          className={styles.select}
        >
          <option value="">-- Select Province --</option>
          {provinces.map((province) => (
            <option key={province.name} value={province.name}>
              {province.name}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.field}>
        <label className={styles.label} htmlFor="branch">
          Select Branch:
        </label>
        <select
          id="branch"
          value={selectedBranch}
          onChange={handleBranchChange}
          className={styles.select}
          disabled={!selectedProvince}
        >
          <option value="">-- Select Branch --</option>
          {filteredBranches.map((branch) => (
            <option key={branch} value={branch}>
              {branch}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default ChooseProvinceBranchView;
