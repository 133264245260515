import React, { useState } from "react";
import { ref, push } from "firebase/database";
import { database } from "../../LoginPage/firebase-config"; // Import your initialized Firebase database instance
import styles from "./OrderForm.module.css"; // Import CSS module
import AdministratorOrders from "./AdministratorOrders";
import { useUserContext } from "../../store/user_context";
import LoginPage from "../../LoginPage/LoginPage";
import { useGlobalContext } from "../../store/context";

const OrderForm = () => {
  const [name, setName] = useState("");
  const [branch, setBranch] = useState("");
  const [numCopies, setNumCopies] = useState(1);
  const [phone, setPhone] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [collectionDate, setCollectionDate] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { myUser } = useUserContext(); // Access the user information from context
  const { LogIn } = useGlobalContext();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Create a new object with the member data
      const newOrder = {
        name,
        branch,
        numCopies,
        phone,
        collectionDate,
      };

      // Define the path in Firebase where you want to store this data
      const orderRef = ref(database, "usbOrders");

      // Push the newOrder object to Firebase with a unique key
      const newOrderRef = await push(orderRef, newOrder);

      // Get the unique key (reference number) generated by Firebase
      const generatedReferenceNumber = newOrderRef.key;

      // Save the reference number in state and show success message
      setReferenceNumber(generatedReferenceNumber);
      setIsSubmitted(true);

      // Optionally, you can reset the form fields after submission
      setName("");
      setBranch("");
      setNumCopies(1);
      setPhone("");
      setCollectionDate("");
    } catch (error) {
      console.error("Error sending usbOrders data to Firebase:", error);
    }
  };

  const handleNewOrder = () => {
    setIsSubmitted(false);
    setReferenceNumber("");
  };

  return (
    <div>
      <div>{LogIn && <LoginPage />}</div>
      {myUser &&
        myUser.email &&
        myUser.email.endsWith("@tac-idwalalethu.com") && (
          <AdministratorOrders />
        )}
      {
        <>
          {isSubmitted ? (
            <div>
              <div className={styles["reference-number"]}>
                <p>Your order reference number is: {referenceNumber}</p>
              </div>
              <button className={styles["b-order"]} onClick={handleNewOrder}>
                Place a New Order
              </button>
            </div>
          ) : (
            <form className={styles["order-form"]} onSubmit={handleSubmit}>
              <p className={styles["msg"]}>
                Each USB costs <strong>R300</strong>. The cost breakdown is as
                follows: <strong>R200</strong> for the USB and{" "}
                <strong>R100</strong> for the data.
              </p>
              <div className={styles["form-group"]}>
                <label className={styles["label-order"]} htmlFor="name">
                  Name:
                </label>
                <input
                  type="text"
                  id="name"
                  className={styles["input-order"]}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className={styles["form-group"]}>
                <label className={styles["label-order"]} htmlFor="branch">
                  Branch:
                </label>
                <input
                  type="text"
                  id="branch"
                  className={styles["input-order"]}
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                  required
                />
              </div>
              <div className={styles["form-group"]}>
                <label className={styles["label-order"]} htmlFor="phone">
                  Phone Number:
                  <span> Format: 10 digits (e.g., 0824567890)</span>
                </label>
                <input
                  type="tel"
                  id="phone"
                  className={styles["input-order"]}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  pattern="[0-9]{10}"
                  placeholder="Format: 1234567890"
                  required
                />
              </div>
              <div className={styles["form-group"]}>
                <label className={styles["label-order"]} htmlFor="numCopies">
                  Number of copies:
                </label>
                <input
                  type="number"
                  id="numCopies"
                  className={styles["input-order"]}
                  value={numCopies}
                  onChange={(e) => setNumCopies(parseInt(e.target.value))}
                  min="1"
                  required
                />
              </div>
              <div className={styles["form-group"]}>
                <label
                  className={styles["label-order"]}
                  htmlFor="collectionDate"
                >
                  Collection Date:
                </label>
                <input
                  type="date"
                  id="collectionDate"
                  className={styles["input-order"]}
                  value={collectionDate}
                  onChange={(e) => setCollectionDate(e.target.value)}
                  required
                />
              </div>
              <button className={styles["b-order"]} type="submit">
                Submit Order
              </button>
            </form>
          )}
        </>
      }
    </div>
  );
};

export default OrderForm;
