import React from "react";
import { useState } from "react";
import FillInPasswordTrasure from "./FillInPasswordTrasure";
const Administrator = () => {
  const [correct, setCorrect] = useState(false);
  const [showQoution, setshowQoution] = useState(false);
  const verify = () => {
    setshowQoution(true);
  };

  return (
    <div>
      {!showQoution && <button onClick={verify}>Check Tithe</button>}
      {showQoution && <FillInPasswordTrasure />}
    </div>
  );
};

export default Administrator;
