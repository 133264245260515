import React, { useState } from "react";
import classes from "./MemberItem.module.css";
import Card from "../MemberCard/Card";
import IdPhoto from "./IdPhoto";
import { useGlobalContext } from "../../../store/context";
import { Link, useNavigate } from "react-router-dom";
import Delete from "./Delete";

const MemberItem = (props) => {
  const [showDeleteSmg, setShowDeleteSmg] = useState(false);
  const { deleteMember } = useGlobalContext();
  const navigate = useNavigate(); // Hook for navigation

  const deleteHandler = () => {
    setShowDeleteSmg(true);
  };

  const hideDeleteHandler = (status) => {
    setShowDeleteSmg(false);
    if (status) {
      deleteMember(props.id);
      navigate("/churchDataBase"); // Navigate to specified route after deletion
    }
  };

  return (
    <div>
      <li>
        <Card className={classes["expense-item"]}>
          <IdPhoto img={props.img} />
          <div className={classes["expense-item__description"]}>
            <div className={classes["discription"]}>
              <div>Title: {props.title}</div>
              <div>Name: {props.name}</div>
              <div>Known As: {props.knownAs}</div>
              <div>Surname: {props.surname}</div>
              <div>Branch: {props.branch}</div>
            </div>
            <div className={classes["discription"]}>
              <div>Cell: {props.cell}</div>
              <div>Alt Cell: {props.cell2}</div>
              <div>Email: {props.emailAddress}</div>
              <div>Emergency: {props.cellEmergency}</div>
            </div>
            <div className={classes["discription"]}>
              <div>Home Place: {props.homePlace}</div>
              <div>Province: {props.province}</div>
              <div>Language: {props.homeLanguage}</div>
              <div>Sealed: {props.sealed}</div>
              <div>Joined: {props.serviceYears}</div>
            </div>
            <div className={classes["discription"]}>
              <div>TAC Number: {props.memberID}</div>
              <div>Occupation: {props.occupation}</div>
              <div>Race: {props.race}</div>
              <div>ID: {props.idNumber}</div>
              <div>Birthday: {props.birthday}</div>
            </div>
            <div className={classes["button_ud"]}>
              <Link
                className={classes["expense-item__pressent"]}
                to={`/updateMember/${props.id}`}
              >
                Edit
              </Link>
              <button
                className={classes["expense-item__absent"]}
                onClick={deleteHandler}
              >
                Delete
              </button>
            </div>
          </div>
        </Card>
        {showDeleteSmg && <Delete onClose={hideDeleteHandler} />}
      </li>
    </div>
  );
};

export default MemberItem;
