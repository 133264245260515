import React, { useState, useEffect } from "react";
import styles from "./ChooseProvinceBranch.module.css";
import { database } from "../../LoginPage/firebase-config";
import { ref, get } from "firebase/database";

const ChooseProvinceBranch = ({ onContinue }) => {
  const [provinces, setProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    const fetchProvincesAndBranches = async () => {
      try {
        const provincesRef = ref(database, "provinces");
        const snapshot = await get(provincesRef);
        const data = snapshot.val();
        // Filter out null values from data array
        const filteredProvinces = data.filter((province) => province !== null);
        setProvinces(
          filteredProvinces.map((province) => ({
            name: province.name,
            branches: province.branches.filter((branch) => !!branch), // Filter out null or falsy branches
          }))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProvincesAndBranches();
  }, []);

  useEffect(() => {
    if (selectedProvince) {
      const selectedProvinceObj = provinces.find(
        (province) => province.name === selectedProvince
      );
      const branches = selectedProvinceObj?.branches || [];
      setBranches(branches);
    } else {
      setBranches([]);
    }
  }, [selectedProvince, provinces]);

  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value);
    setSelectedBranch(""); // Reset branch when province changes
  };

  const handleBranchChange = (event) => {
    setSelectedBranch(event.target.value);
    // Fetch members when branch is selected
    if (selectedProvince && event.target.value) {
      onContinue(selectedProvince, event.target.value);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.field}>
        <label className={styles.label} htmlFor="province">
          Select Province:
        </label>
        <select
          id="province"
          value={selectedProvince}
          onChange={handleProvinceChange}
          className={styles.select}
        >
          <option value="">-- Select Province --</option>
          {provinces.map((province) => (
            <option key={province.name} value={province.name}>
              {province.name}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.field}>
        <label className={styles.label} htmlFor="branch">
          Select Branch:
        </label>
        <select
          id="branch"
          value={selectedBranch}
          onChange={handleBranchChange}
          className={styles.select}
          disabled={!selectedProvince || branches.length === 0}
        >
          <option value="">-- Select Branch --</option>
          {branches.map((branch) => (
            <option key={branch} value={branch}>
              {branch}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default ChooseProvinceBranch;
