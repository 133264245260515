import React from "react";
import { useUserContext } from "../../store/user_context";
import Administrator from "./Administrator";
import UnauthorizedPage from "../NotAuthorised/NotAuthorised";
import Treasure from "./Treasurer";
const Tithe = () => {
  const { myUser } = useUserContext();

  if (
    !myUser ||
    !myUser.email ||
    !myUser.email.endsWith("@tac-idwalalethu.com")
  ) {
    return <UnauthorizedPage />;
  }

  return (
    <div>
      <Administrator />
      <Treasure />
    </div>
  );
};

export default Tithe;
