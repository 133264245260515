// FetchMembers.jsx
import React, { useState, useEffect } from "react";
import { database } from "../../LoginPage/firebase-config";
import { ref, get } from "firebase/database";
import MemberItem from "./MemberItem";
import "./MemberItem.css";

const FetchMembers = ({ selectedProvince, selectedBranch }) => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const membersRef = ref(database, "members");
        const snapshot = await get(membersRef);
        const membersData = snapshot.val();

        // Filter members based on selected province and branch
        const filteredMembers = Object.values(membersData).filter(
          (member) =>
            member.province === selectedProvince &&
            member.branch === selectedBranch
        );

        setMembers(filteredMembers);
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };

    if (selectedProvince && selectedBranch) {
      fetchMembers();
    }
  }, [selectedProvince, selectedBranch]);

  return (
    <div>
      <h2 className="h2_member">
        Members in {selectedBranch}, {selectedProvince}
      </h2>
      <ul className="member-list">
        {members.map((member) => (
          <MemberItem
            key={member.id}
            id={member.id}
            title={member.title}
            knownAs={member.knownAs}
            surname={member.surname}
            img={member.img}
            branch={member.branch}
          />
        ))}
      </ul>
    </div>
  );
};

export default FetchMembers;
