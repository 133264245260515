// RecordTithe.jsx
import React, { useState } from "react";
import ChooseProvinceBranch from "./ChooseProvinceBranch";
import FetchMembers from "./FetchMembers";
import "./MemberItem.css";
const RecordTithe = () => {
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");

  const handleContinue = (province, branch) => {
    setSelectedProvince(province);
    setSelectedBranch(branch);
  };

  return (
    <div>
      <h2 className="h2_title">Record Tithe</h2>
      <ChooseProvinceBranch onContinue={handleContinue} />
      {selectedProvince && selectedBranch && (
        <FetchMembers
          selectedProvince={selectedProvince}
          selectedBranch={selectedBranch}
        />
      )}
    </div>
  );
};

export default RecordTithe;
