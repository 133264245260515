import React, { useState, useEffect } from "react";
import { useUserContext } from "../../../store/user_context";
import { useNavigate } from "react-router-dom";
import ShowAllMembers from "../ShowAllMembers";
import AllMember from "./AllMember";
import classes from "./Database.module.css";
import { useGlobalContext } from "../../../store/context";
import FetchProvinceBranch from "./FetchProvinceBranch";

const Database = () => {
  const navigate = useNavigate();
  const { myUser } = useUserContext();
  const { cart } = useGlobalContext();

  useEffect(() => {
    if (!myUser) {
      navigate("/unauthorizedpage");
    }
  }, [myUser, navigate]);

  // Initialize memberS based on cart data, excluding "active": "no" members
  const memberS = Array.from(cart.entries())
    .map(([id, item]) => ({
      id,
      ...item,
    }))
    .filter((member) => member.active !== "no");

  const [showAllMember, setShowAllMember] = useState(false);
  const [showNewMember, setShowNewMember] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [memberFiltered, setMemberFiltered] = useState(memberS);

  const handleContinue = (province, branch) => {
    setSelectedProvince(province);
    setSelectedBranch(branch);
  };

  useEffect(() => {
    let filteredMembers = memberS;

    if (selectedProvince) {
      filteredMembers = filteredMembers.filter(
        (member) => member.province === selectedProvince
      );
    }

    if (selectedBranch) {
      filteredMembers = filteredMembers.filter(
        (member) => member.branch === selectedBranch
      );
    }

    setMemberFiltered(filteredMembers);
  }, [selectedProvince, selectedBranch, memberS]);

  const toggleMemberTableHandler = () => {
    setShowAllMember((prevShowAllMember) => !prevShowAllMember);
  };

  const toggleNewMemberHandler = () => {
    setShowNewMember((prevShowNewMember) => !prevShowNewMember);
  };

  return (
    <div className={classes.parentContainer}>
      <h2 className="h2_title">Record Tithe</h2>
      <FetchProvinceBranch onContinue={handleContinue} />

      <div className={classes.container}>
        <div className={classes.buttons}>
          <button
            className={classes.buttonAdd}
            onClick={toggleMemberTableHandler}
          >
            {!showAllMember ? "Show List View" : "Edit Members"}
          </button>
          <button
            className={classes.buttonAdd}
            onClick={toggleNewMemberHandler}
          >
            {!showNewMember ? "Show New Members" : "Show All Members"}
          </button>
        </div>
      </div>

      {/* Conditional rendering of tables */}
      {showAllMember ? (
        <ShowAllMembers
          tableData={memberFiltered.filter((member) =>
            showNewMember ? member.sealed === "no" : true
          )}
        />
      ) : (
        <AllMember showNewMember={showNewMember} memberS={memberFiltered} />
      )}
    </div>
  );
};

export default Database;
