import "./ExpenseItem.css";
import Card from "../../UI/MemberCard/Card";
import IdPhotoRound from "./IdPhotoRound";
import classes from "./MealItem.module.css";
import { useGlobalContext } from "../../../store/context";

const MemberItem = (props) => {
  const { increase, decrease, branch_Date } = useGlobalContext();
  const increaseHandler = () => {
    increase(props.id, branch_Date);
  };

  const decreaseHandler = () => {
    const apologySMS = window.prompt("Please enter the apology SMS:");

    if (apologySMS !== null && apologySMS.trim() !== "") {
      const updatedAttendance = {
        ...props.attendance,
        apologySMS: apologySMS,
      };

      decrease(props.id, updatedAttendance);
    }
  };

  return (
    <li>
      <Card className="expense-item">
        <IdPhotoRound img={props.img} />
        <div className="expense-item__description">
          <div className="discription">
            <div>{props.title}</div>
            <h3>{props.knownAs}</h3>
            <h3>{props.surname}</h3>
            <div className={classes.price}>{props.branch}</div>
          </div>
        </div>
      </Card>
    </li>
  );
};

export default MemberItem;
